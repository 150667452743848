import { useState } from 'react'
import bird from './svg/bird.svg'
import cat from './svg/cat.svg'
import cow from './svg/cow.svg'
import dog from './svg/dog.svg'
import gator from './svg/gator.svg'
import heart from './svg/heart.svg'
import horse from './svg/horse.svg'

const svgMap = { // สร้าง object ขึ้นมาเพื่อใช้เป็น Path ในการสื่อไปยังตัวแปรรูปภาพด้านบน
    bird,
    cat,
    cow,
    dog,
    gator,
    horse
}



function AnimalShow ({type}){  // รับ props  มาจาก ฟังชั่น Map ในไฟล์ App

    const [click,setClicks] = useState(0) // ประกาศตัวแปรแบบ useState ค่าเริ่มต้นของ click เท่ากับ 0

    const handleClick = () =>  {  // สร้างฟันก์ชั่น State System 
        setClicks(click + 1 )  // เมื่อ user คลิก ทำให้ค่า click + 1
    }

    return (
        <div className='animal-show' >
             <div>
            <img src={svgMap[type]} alt='animal' onClick={handleClick} style={{cursor:'pointer'}} className='animal'/>    {/*แท็กรูปภาพที่แสดงเป็น  key จาก object svgMap  **ที่ใช้ [] ในการเรียก object เพราะมีการเรียกใช้ type ที่เป็น props มาจาก  Component App ซึ่งไม่ใช่ key ของ object svgMap และมีเหตุการณ์ที่รูปหัวใจจะขยายใหญ่ขึ้นเมื่อ onClick(คลิก) ที่รูปสัตว์หรือรูปหัวใจ*/}
            <img src= {heart} alt='heart'style={{width: 10 +10  *  click ,cursor:'pointer'}}  onClick={handleClick} className='heart'/>   {/*แท็กรูปภาพหัวใจ ที่จะแสดงพร้อมกับรูปสัตว์ และมีเหตุการณ์ที่รูปหัวใจจะขยายใหญ่ขึ้นเมื่อ onClick(คลิก) ที่รูปสัตว์หรือรูปหัวใจ โดยจะนำขนาด width ไป คูณกับ จำนวนค่าของ click*/}
            </div>
        </div>
    )
}

export default AnimalShow  //Export Component  Animalshow เพื่อนำไปใช้ที่ Component อื่นๆ
