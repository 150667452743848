import {useState} from 'react'
import AnimalShow from './AnimalShow'
import './App.css'
import './Animal.css'

function randomAnimal (){   // สร้างฟังชั่นเพื่อ Random  ประเภทของสัตว์ใน Array
 const animals = ['bird','cat','cow','dog','gator','horse'] // คนละตัวแปร animal ใน useState  ใช้ตัวแปรแยกกัน
 return animals[Math.floor(Math.random()* animals.length)]
}
 
function App(){

   const [animal,setAnimal] = useState([]); // ประกาศตัวแปรแบบ useState // animal คือตัวแปร //setAnimal คือ setter ที่ใช้กำหนดค่าเมื่อต้องการให้ค่าใน animal เปลี่ยน // useState() ค่าในวงเล็บคือค่าเริ่มต้นของ animal ในตัวอย่างนี้กำหนดให้เป็น [] มีค่าเท่ากับ Array เปล่า
   const [click,setClick] = useState(false);
    const handleClick = () =>{   // -> State System หมายถึงต้องการให้เกิดอะไรขึ้นเมื่อผู้ใช้ ทำ Event System (อยู่ด้านล่าง)
       setAnimal([...animal,randomAnimal()])    // -> เกิดเหตุการณ์นำค่าที่ random ได้จากฟังชันก์ randomAnimal มาเพิ่มลงในค่าของ animal ใน useState
       setClick(true);
    }


const renderAnimals = animal.map((animal,index) =>{  //ใช้ map เพื่อสร้าง Array ใหม่ และ return  ค่าไปยัง Component ของ ไฟล์ Animalshow โดยส่งไปแบบ props และ key เป็นส่วนที่ React ต้องการให้ระบุ  *** อันนี้สำคัญใช้บ่อย
    return <AnimalShow type = {animal} key = {index} />
})

    return (
        <div className ='app'>
            <h1 className='forCenter'>Welcome to my mini project Random picture of animal</h1>
            <div className='forCenter'><button  onClick ={handleClick}>Random animal !</button></div>
            <br></br>
            {click && <div className='forCenter'> you can click on animal picture for big heart!</div> }
                {/*Event System จะเกี่ยวเนื่องกับ State System ในตัวอย่างนี้ Even System คือ การที่ user onClick(คลิกที่ปุ่ม) Add animal ทำให้เกิด State System ด้านบน ที่ประกาศตัวแปรไว้ชื่อ handleClick */}
            <div className='animal-list'>{renderAnimals}</div>  {/*นำ */}
        </div>
    )
}

export default App